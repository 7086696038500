import api from '../../utils/api'

// 换取地址
export async function get_luohu_url (payload) {
  return api.get('/api/v1/mp/luohu/landing/message_url', {params: payload})
}

// 换取地址
export async function get_wfy_url (payload) {
  return api.get('/api/v1/wyyy/landing/message_url', {params: payload})
}
