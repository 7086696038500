<template>
  <g-loading :loading="true" />
</template>

<script>
import { get_luohu_url, get_wfy_url } from "./service.js"

export default {
  data() {
    return {};
  },
  computed: {},
  components: {},
  created() {
    this.submit_form()
  },
  methods: {
    async submit_form() {
      const { scene } = this.$route.query
      // 温附一
      if (scene === '1') {
        const {data} = await get_wfy_url(this.$route.query)
        location.href = data.url
      }
      // 罗湖
      else if (scene === '2') {
        const {data} = await get_luohu_url(this.$route.query)
        location.href = data.url
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
